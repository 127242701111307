import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { generateViewerDataForSegments, jsonToUrlParams } from '../../utils/common';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isResultsLoading: false,
  error: null,
  segments: [],
  results: [],
  savedAudienceReports: null
};

const slice = createSlice({
  name: 'audiences',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startResultsLoading(state) {
      state.isResultsLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    
    getUserAudienceReportData(state,action){
      state.isLoading = false;
      state.savedAudienceReports = action.payload
    },

    // GET SEGMENTS RESULT
    getSegmentsSuccess(state, action) {
      state.isLoading = false;
      const segmentsData = action.payload;
      state.segments = segmentsData.map((segment) => {
        const data = JSON.parse(segment.data);
        return { ...segment, data };
      });
    },
    getResultsSuccess(state, action) {
      state.isResultsLoading = false;
      const resultsData = action.payload;
      const data = resultsData.map((result) => generateViewerDataForSegments(result));
      state.results = data;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------
export function getSegments(token) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/audience?Token=${token}`);
      dispatch(slice.actions.getSegmentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getAudienceReportsMethod(params){
  const response = await axios.post(`audience/results`, params);
  return response
}

export async function getProfileViews(data){
  // let response = ""
  // if(data.allViews)
  //   response = await axios.get(`/views/getViews?vooKey=${data.vooKey}&videoID=${data.videoID}&customViewerID=${data.customViewerID}&onlyWatched=${data.onlyWatched}&allViews=${data.allViews}&combineViews=${data.combineViews}`)
  // else if (!data.allViews && (data.videoGroupID === '' || data.videoGroupID === undefined))
  //   response = await axios.get(`/views/getViews?vooKey=${data.vooKey}&videoID=${data.videoID}&customViewerID=${data.customViewerID}&onlyWatched=${data.onlyWatched}&allViews=${data.allViews}&combineViews=${data.combineViews}&start=${data.start}&end=${data.end}`)
  // else
  //   response = await axios.get(`/views/getViews?vooKey=${data.vooKey}&videoID=${data.videoID}&customViewerID=${data.customViewerID}&onlyWatched=${data.onlyWatched}&allViews=${data.allViews}&combineViews=${data.combineViews}&start=${data.start}&end=${data.end}&videoGroup=${data.videoGroupID}`)  
  //   return response
  const baseUrl = '/views/getViews';

  const params = new URLSearchParams({
    vooKey: data.vooKey,
    videoID: data.videoID,
    customViewerID: data.customViewerID,
    onlyWatched: data.onlyWatched,
    allViews: data.allViews,
    combineViews: data.combineViews,
  });

  if (!data.allViews) {
    if (data.start) 
      params.set('start', data.start);
    if (data.end) 
      params.set('end', data.end);
    if (data.videoGroupID) 
      params.set('videoGroup', data.videoGroupID);
  }

  const response = await axios.get(`${baseUrl}?${params.toString()}`);
  return response;
}

// export async function getCombineRecords(data){
//   const response = await axios.get(`/views/getViews?vooKey=${data.vooKey}&videoID=${data.videoID}&customViewerID=${data.customViewerID}&onlyWatched=${data.onlyWatched}&allViews=${data.allViews}&combineViews=${data.combineViews}`)
//   return response
// }

export async function deleteViewer(data){
  const response = await axios.get(`/deleteViewer?vooKey=${data.vooKey}&customViewerID=${data.customViewerID}`)
  return response
}

export function getResults(data) {
  return async () => {
    dispatch(slice.actions.startResultsLoading());
    try {
      const response = await getAudienceReportsMethod(data)
      dispatch(slice.actions.getResultsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export async  function automateAudienceReport(user, params){
  const para = jsonToUrlParams(params)
  const url = `audience/save?userID=${user.id}&${para}`
  const response = await axios.get(url);    
  return response  
}
export async  function deleteReports( id ){
  const url = `reports/delete?id=${id}`
  return axios.delete(url); 
}
export const getUserAudienceReportApi =(userId)=>{
  dispatch(slice.actions.startLoading());
  return async () => {
    const userReportUrl = `/audience/get?userID=${userId}`;
    try{
      const response = await axios.get(userReportUrl)
      dispatch(slice.actions.getUserAudienceReportData(response.data));
      return true
    }
    catch(error){
      dispatch(slice.actions.hasError(error));
      return false
    }
  }
}